import anime from "animejs";

export function toggleMenu() {
  const hamburger = document.querySelector('#hamburger');
  const mainMenu = document.querySelector('#main-menu-mobile');
  if (window.matchMedia("(max-width: 768px)").matches) {
    const tlOpen = anime.timeline({
      targets: mainMenu,
      easing: 'easeInOutSine',
      autoplay: false,
    }).add({
      height: ['0vh', '100vh'],
      delay: 10,
      duration: 1, // Instant height expansion
    }).add({
      opacity: [0, 1],
      duration: 300, // Fade in,
      delay: 100,
    });

    const tlClose = anime.timeline({
      targets: mainMenu,
      easing: 'easeInOutSine',
      autoplay: false,
    }).add({
      opacity: [1, 0],
      duration: 300, // Fade out,
      delay: 0,
    }).add({
      height: ['100vh', '0vh'],
      delay: 100,
      duration: 1, // Instant height reduction
      complete: function () {
        mainMenu.classList.remove('active');
      }
    });

    if (hamburger != null) {
      hamburger.addEventListener('click', () => {
        document.body.classList.toggle('open');
        hamburger.classList.toggle('open');

        if (!mainMenu.classList.contains('active')) {
          mainMenu.classList.add('active');
          tlClose.pause(); // Pause closing animation if it was playing
          tlOpen.restart(); // Restart and play the opening animation
        } else {
          tlOpen.pause(); // Pause opening animation if it was playing
          tlClose.restart(); // Restart and play the closing animation
        }
      });
    }
  }
}
